import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { DEFAULT_INSTRUCTIONS } from '../../utils/conversation_config';

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
};

interface SettingsModalProps {
  isOpen: boolean;
  onPromptSave: (prompt: string) => void;
  onClose: () => void;
}

const SettingsModal = ({
  isOpen,
  onPromptSave,
  onClose,
}: SettingsModalProps) => {
  const [prompt, setPrompt] = useState('');

  useEffect(() => {
    const systemPrompt = localStorage.getItem('system-prompt');
    setPrompt(systemPrompt || DEFAULT_INSTRUCTIONS);
  }, []);

  const handleSaveClick = () => {
    onPromptSave(prompt);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ zIndex: '100000' }}
    >
      <Box sx={boxStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit system prompt
        </Typography>
        <br />
        <TextField
          id="outlined-multiline-flexible"
          label="System prompt"
          multiline
          minRows={5}
          maxRows={20}
          style={{ width: '100%' }}
          onChange={(e) => setPrompt(e.target.value)}
          value={prompt}
        />
        <div
          style={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button style={{ padding: '5px 20px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ padding: '5px 20px' }}
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default SettingsModal;
